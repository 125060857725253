var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          Object.assign({}, _vm.helpers.validIcons, {key: 'first_day'}),
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/property_generation_dates',
          route: '/admin/parameters/property_generation_dates',
          name: 'property_generation_date',
          params: { _lists: 'months' },
          duplicate: true,
          latest: 'month_id'
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }