<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            { ...helpers.validIcons, key: 'first_day' },
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/property_generation_dates',
            route: '/admin/parameters/property_generation_dates',
            name: 'property_generation_date',
            params: { _lists: 'months' },
            duplicate: true,
            latest: 'month_id'
          }"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyGenerationDates',
  mixins: [crudMixin]
}
</script>
